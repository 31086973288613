main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

ul {
  list-style-type: none;
}

.navbar {
  background: #006243;
  color: white;
}

.navbar-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.nav-left {
  align-items: left;
}

.nav-center {
  align-items: center;
}

.nav-right {
  align-items: right;
}

.product-card {
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card img {
  height: 150px;
  width: 200px;
  object-fit: contain;
}

.footer {
  background-color: #880808; /* Primary background color */
  color: #C0C0C0; /* Text color */
  padding: 20px;
}

.footer-col {
  margin-bottom: 20px; /* Add spacing between columns */
}

.footer h3, .footer h5 {
  color: #C0C0C0;
}

.footer-link {
  color: #C0C0C0; /* Link color */
  text-decoration: none;
}

.footer-link:hover {
  color: #097969; /* Link hover color */
}

.footer form {
  background-color: #097969; /* Form background color */
  padding: 20px;
  border-radius: 5px;
}

.footer form .form-control {
  margin-bottom: 10px;
}

.footer form .btn {
  background-color: #880808; /* Button color */
  border: none;
}

.footer form .btn:hover {
  background-color: #C0C0C0; /* Button hover color */
  color: #880808;
}

footer {
  border-top: 1px solid #C0C0C0;
  padding-top: 10px;
  margin-top: 20px;
}



/*.welcome ::before {
  content: '';
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, #880808, #097969, #C0C0C0);
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  transform: scaleX(1.5);
}*/

.contact-screen {
  padding: 20px;
}

.contact-screen h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form {
  background-color: #097969;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.contact-form .form-control {
  margin-bottom: 15px;
}

.contact-form .form-text {
  font-size: 0.9rem;
  color: #fff;
}

.contact-form button {
  width: 100%;
}

.product-card {
  height: 400px; /* Set a fixed height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card img {
  height: 150px; /* Set a fixed height for the image */
  object-fit: contain;
}

.product-card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rating span {
  margin: 0.1rem;
}

h3 {
  padding: 1rem 0;
}

.product-screen-image {
  height: 325px;
}

.product-modal {
  background: linear-gradient(to right, #880808, #097969, #C0C0C0);;
}

.product-modal-rounded {
  border-radius: 10px;
}

.form-border {
  border: 2px solid;
  border-image: linear-gradient(to right, #880808, #097969, #C0C0C0) 1;
  border-radius: 25px;
  padding: 20px;
  margin-top: 20px;
}

/* Custom button */
.btn-custom {
  color: #C0C0C0;
  background-color: #097969;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-custom:hover {
  background-color: #880808;
  color: white;
}
